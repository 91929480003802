import './styles.css';

const Blog = () => {
	return (
		<div className="section">
			<div className="card">
				<div className="card-body">
					<h2 style={{ color: '#545e69' }}>Blogs</h2>
					<h4>Coming soon!</h4>
				</div>
			</div>
		</div>
	);
};

export default Blog;
